<template>
  <div>
    <!-- Filters -->
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibir</label>
            <v-select
              v-model="itemsPerPage"
              dir="ltr"
              :options="itemsPerPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="search(currentPage)"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <label>itens</label>
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                v-if="createVersionEnabled"
                variant="primary"
                :to="{ name: 'announcement-save' }"
              >
                <feather-icon icon="PlusCircleIcon" class="mr-50" />
                <span class="text-nowrap">Novo comunicado</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        id="sys-version-table"
        hover
        class="position-relative"
        :items="items"
        responsive
        :fields="showColumns"
        primary-key="id"
        show-empty
        empty-text="Não foram encontrados produtos para esta busca"
        :busy="loading"
      >
        <!-- ColuAPP_BACKOFFICE_ROLE_ID, APP_FINANCIAL_ROLE_ID, APP_ADMIN_ROLE_ID, APP_CUSTOMER_ROLE_IDmn: version -->
        <template #cell(version)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.version }}</span>
          </div>
        </template>
        <!-- Column: description -->
        <template #cell(description)="data">
          <div class="text-wrap">
            <h1 class="align-text-top">
              Comunicado - {{ bringMeTheDate(data.item.created_at) }}
            </h1>
            <br />
            <span class="align-text-top" v-html="data.item.description"></span>
          </div>
        </template>
        <!-- Column: notify_user -->
        <template #cell(notify_users)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.notify_users | assertion
            }}</span>
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            v-if="updateVersionEnabled || deleteVersionEnabled"
            variant="link"
            no-caret
            right
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="updateVersionEnabled"
              :to="{ name: 'announcement-save', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="deleteVersionEnabled"
              @click="deleteItem(data.item)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Remover</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Exibindo {{ pageItemsFrom }} à {{ pageItemsTo }} de
              {{ totalItems }} itens</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="itemsPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="search"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {
  CREATE_ACTION,
  UPDATE_ACTION,
  DELETE_ACTION,
  ANNOUNCEMENT_RESOURCE,
} from "@/constants/resources";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import * as accountTypes from "@/modules/account/store/types";
import { APP_ADMIN_ROLE_ID, APP_MASTER_ROLE_ID } from "@/constants/auth";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect,
  },
  setup() {
    const { skin } = useAppConfig();

    return { toast: useToast(), skin };
  },
  data() {
    return {
      loading: false,
      currentPage: 1,
      itemsPerPage: 1,
    };
  },
  computed: {
    ...mapGetters({
      searchResult: types.VERSIONS_SEARCH_RESULT,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
      user: accountTypes.USER,
    }),
    showColumns: function () {
      return [
        { key: "version", label: "Versão", sortable: true },
        { key: "description", label: "Descrição" },
        { key: "notify_users", label: "Notificar usuários" },
        { key: "actions", label: "Ações" },
      ];
    },
    createVersionEnabled: function () {
      return this.$can(CREATE_ACTION, ANNOUNCEMENT_RESOURCE);
    },
    updateVersionEnabled: function () {
      return this.$can(UPDATE_ACTION, ANNOUNCEMENT_RESOURCE);
    },
    deleteVersionEnabled: function () {
      return this.$can(DELETE_ACTION, ANNOUNCEMENT_RESOURCE);
    },
    items: function () {
      return this.searchResult?.data || [];
    },
    totalItems: function () {
      return this.searchResult?.total || 0;
    },
    pageItemsFrom: function () {
      return this.searchResult?.from || 0;
    },
    pageItemsTo: function () {
      return this.searchResult?.to || 0;
    },
  },
  mounted() {
    this.itemsPerPage = this.initialItemsPerPage;
    this.search();
  },
  methods: {
    ...mapActions({
      searchVersions: types.SEARCH_VERSIONS,
      deleteVersion: types.DELETE_VERSION,
    }),
    search(currentPage) {
      this.loading = true;
      this.searchVersions({
        limit: this.itemsPerPage,
        currentPage: Number.isInteger(currentPage)
          ? currentPage
          : this.currentPage,
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar as versões. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteItem(item) {
      this.$swal({
        title: "Tem certeza?",
        text: `Você removerá a versão ${item.version}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar",
        background: `${this.skin === "dark" ? "#283046" : ""}`,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-secondary ml-1",
          htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
        },
      }).then((result) => {
        if (result.value) {
          this.deleteVersion(item.id)
            .then((response) => {
              this.search();
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Sucesso",
                  text: `A versão ${item.version} foi removida!`,
                  icon: "CoffeeIcon",
                  variant: "success",
                },
              });
            })
            .catch((error) => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Oops!",
                  text: `Ocorreu um erro ao remover a versão ${item.version}. Entre em contato com o setor de TI.`,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    bringMeTheDate(value) {
      if (value && value !== null) {
        const dateStr = value.split("T");
        if (dateStr.length > 1) {
          const dateParts = dateStr[0].split("-");
          if (dateParts.length === 3) {
            return dateParts[2] + "/" + dateParts[1] + "/" + dateParts[0];
          }
        }
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#sys-version-table img {
  max-width: 75rem;
}
</style>
